<template>
	<div class="gg-container">
		
		<div class="search-container">
			
			<!--按钮样式-->
			<div style="margin-left: auto; display: flex; justify-content: space-around">
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handleBtn01">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">打开弹出框</span>
					</el-button>
				</div>
				
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handleBtn01">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px;font-size: 12px">打开弹出框</span>
					</el-button>
				</div>
			
			</div>
		
		</div>
		
		<!--传入宽度改变弹出框大小-->
		<dialogOneTest :isActiveDialog.sync="isActiveDialog"
									 @getList="getList"
									 v-if="isActiveDialog"
									 :width="640"
		></dialogOneTest>
	
	</div>
</template>

<script>
import dialogOneTest from './components/dialogOneTest';
import dialogTest from './components/dialogTest';

export default {
  name: "dialogOne",
  components: {
    dialogOneTest,
  },
  data () {
    return {
      isActiveDialog: false,
      info: {
        a: 1,
      }
    }
  },
  methods: {
    getList () {
      console.log('getList触发')
    }, // 如果dialog数据更新,回调getList()刷新数据
    handleBtn01 () {
      this.isActiveDialog = true;
    },
  }
}
</script>

<style scoped>

</style>
